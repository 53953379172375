import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../images/search-icon.svg';

const SearchBar = ({ term, setTerm, onSearchSubmit, clearResults }) => {
	SearchBar.propTypes = {
		onSearchSubmit: PropTypes.any,
		clearResults: PropTypes.any
	};
	const { t } = useTranslation();

	return (
		<>
			<div className="searchbar">
				<div>
					<input
						className="searchbar-input"
						type="text"
						placeholder={t('search_products_brands')}
						onChange={e => setTerm(e.target.value)}
						autoFocus
						value={term}
					/>
					<img src={searchIcon} alt="SEARCH" />
				</div>
				{/* <button>{t('search')}</button> */}
			</div>
		</>
	);
};

export default SearchBar;
