import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerCartQuantityAction } from 'store/actions.js';
import { api_getCustomerCartItems, api_refreshCartItems, api_updateCustomerDBCart } from '../../backend/api_calls';
import ShopCart from '../../backend/shop_cart';
import UserSession from '../../backend/user_session';

export default function UpdateCartDB() {
	const dispatch = useDispatch();
	const successMessage = useSelector(state => state.authReducer.message);
	const firstUpdate = useRef(true);

	useEffect(() => {
		if (successMessage.length === 0 && firstUpdate.current === false) return;

		let cartUpdated = false;
		let cartItemsInLS = ShopCart.getItems();

		//remove product Entity to update it from the DB because sometimes it bugs out
		cartItemsInLS.forEach(x => delete x.product);

		if (UserSession.isAuthenticated()) {
			if (cartItemsInLS.length === 0) {
				api_getCustomerCartItems()
					.then(json => {
						if (json.items.length === 0) return;

						ShopCart.setDBCart(json.items);
						dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
						cartUpdated = true;
					})
					.catch(err => console.error(err));
			} else {
				api_updateCustomerDBCart({ items: cartItemsInLS });
			}
		}

		//if localStorage's cart was not updated, do it now
		if (!cartUpdated && cartItemsInLS.length > 0) {
			api_refreshCartItems({ items: cartItemsInLS })
				.then(json => {
					ShopCart.setDBCart(json.items);
					dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
				})
				.catch(err => console.error(err));
		}

		cartItemsInLS = ShopCart.getItems();

		firstUpdate.current = false;
	}, [successMessage]);

	return null;
}
