import { api_searchCategories, api_searchProducts, api_searchProductsWithFilters } from 'backend/api_calls';
import ProductBox from 'components/common/ProductBox';
import SearchBar from 'components/common/SearchBar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import { CategorySearchLoader, ProductSearchLoader } from 'components/common/SkeletonLoaders';
import useDebounce from 'components/hooks/useDebounce';
import close from 'images/close-button.svg';
import { useEffect } from 'react';

const Search = ({ setSearchTransition }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [term, setTerm] = useState('');
	const [searchResults, setSearchResults] = useState({ cats: [], prds: [] });
	const debouncedSearchTerm = useDebounce(term, 700);

	useEffect(() => {
		if (debouncedSearchTerm) {
			setLoading(true);

			Promise.all([
				api_searchCategories(term)
					.then(json => {
						return json.cats;
					})
					.catch(error => {
						console.log(error);
					}),

				api_searchProductsWithFilters(term, 0, null, 'pdate desc', 1, 3)
					.then(json => {
						return json.items;
					})
					.catch(error => {
						console.log(error);
					})
			]).then(data => {
				setSearchResults({ cats: data[0], prds: data[1] });
				setLoading(false);
			});
		} else {
			setSearchResults({ cats: [], prds: [] });
			setLoading(false);
		}
	}, [debouncedSearchTerm]);

	const clearResults = () => {};

	return (
		<div
			id="search-screen"
			className={`search-screen ${debouncedSearchTerm.trim().length > 2 ? 'search-clicked' : ''}`}>
			<div className="content-wrapper">
				<div className="exit-search">
					<div
						id="search-close"
						onClick={() => {
							setSearchTransition(false);
						}}>
						<img src={close} alt="close" />
					</div>
				</div>

				<SearchBar term={term} setTerm={setTerm} clearResults={clearResults} />

				<div
					className="results-section after-search-section"
					onClick={() => {
						setSearchTransition(false);
					}}>
					<div className="results-container">
						<div className="keyword-results">
							<div className="keyword-title">{t('recommendations')}</div>
							{loading ? (
								<CategorySearchLoader />
							) : searchResults.cats.length === 0 ? (
								<p className="no-results">{t('search_keywords_results_none')}</p>
							) : (
								searchResults.cats.map(category => (
									<Link className="keyword-item" key={category.id} to={category.eurl ? category.eurl : ''}>
										{category.nm}
									</Link>
								))
							)}
						</div>

						<div className="products-results">
							<div className="keyword-title">{t('products')}</div>
							{loading ? (
								<ProductSearchLoader />
							) : searchResults.prds.length === 0 ? (
								<p className="no-results">{t('search_products_results_none')}</p>
							) : (
								searchResults.prds.map(product => (
									<Link className="product-item" key={product.id} to={product.eurl ? product.eurl : ''}>
										<ProductBox key={product.id} product={product} />
									</Link>
								))
							)}
						</div>
					</div>

					{!loading && (
						<div className="show-all-container">
							<Button
								className="show-all"
								color="secondary"
								actionOnClick={() => navigate(`/search?searchquery=${term}`)}>
								ΠΡΟΒΟΛΗ ΟΛΩΝ
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

Search.propTypes = {
	searchTransition: PropTypes.bool,
	setSearchTransition: PropTypes.func
};

export default Search;
