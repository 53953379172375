import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CheckoutHeader from './checkout/CheckoutHeader';
import ClassicHeader from './store/ClassicHeader';
{
	/* import { sendUserInfoEvent } from "../../common/gtm.js" */
}

const Header = () => {
	const { pathname } = useLocation();
	const [searchTransition, setSearchTransition] = useState(false);

	useEffect(() => {
		setCloseModalBtnListener();
	}, []);

	const setCloseModalBtnListener = () => {
		function handleClickOutside(event) {
			// let modal = window.document.getElementById('menu-section');
			// let burger = window.document.getElementById('burger-btn');
			// let header = window.document.getElementsByTagName('header')[0];
			// if (modal && !modal.contains(event.target) && burger && !burger.contains(event.target)) {
			// 	modal.style.opacity = '0';
			// 	modal.style.pointerEvents = 'none';
			// 	modal.classList.remove('show');
			// 	header.classList.remove('line-header');
			// 	window.document.getElementById('burger-btn').classList.remove('active');
			// 	{
			// 		/*visiblemenu.style.display = "inline-flex";*/
			// 	}
			// }
		}
		document.addEventListener('mousedown', handleClickOutside);
	};

	if (pathname === '/cart' || pathname === '/checkout-step-1') {
		return <CheckoutHeader step={1} />;
	} else if (pathname === '/checkout-step-2') {
		return <CheckoutHeader step={2} />;
	} else if (pathname === '/checkout-step-3') {
		return <CheckoutHeader step={3} />;
	} else if (pathname === '/CheckoutSuccess' || pathname === '/CheckoutFailure') {
		return <CheckoutHeader step={4} />;
	} else {
		return <ClassicHeader searchTransition={searchTransition} setSearchTransition={setSearchTransition} />;
	}
};

export default Header;
