import ShopCart from '../../backend/shop_cart';

const initialState = {
	error: '',
	cartMsg: '',
	loading: false,
	showCartWidget: false,
	headerCartQuantity: ShopCart.getTotalQuantity()
};

const cartReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'add-to-cart':
			state = { ...state, loading: true };
			break;
		case 'remove-from-cart':
			state = { ...state, loading: true };
			break;
		case 'open-cart-widget':
			state = { ...state, showCartWidget: action.bool };
			break;
		case 'cart-update-success':
			state = { ...state, cartMsg: action.message, loading: false };
			break;
		case 'header-cart-quantity':
			state = { ...state, headerCartQuantity: action.headerCartQuantity };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default cartReducer;
