import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './css/style.scss';

import App from './App';

import './i18n.js';

import { Provider } from 'react-redux';
import store from 'store/index';

//import reportWebVitals from './reportWebVitals';

const app = (
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
