import { combineReducers } from 'redux';

// Combine all reducers in here

// Auth
import authReducer from 'store/auth/reducer';

// Cart
import cartReducer from 'store/cart/reducer';

// Checkout
import checkoutReducer from 'store/checkout/reducer';

// Register
import registerReducer from 'store/register/reducer';

// History
import historyReducer from 'store/history/reducer';

// Layout
import layoutReducer from 'store/layout/reducer';

// Filters
import filtersReducer from 'store/filters/reducer';

const rootReducer = combineReducers({
	authReducer,
	checkoutReducer,
	registerReducer,
	historyReducer,
	layoutReducer,
	filtersReducer,
	cartReducer
});

export default rootReducer;
