import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SlideLoader = ({ width, height }) => {
	return (
		<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
			<Skeleton style={{ minHeight: width ?? '550px', height: height ?? '70vh', display: 'block' }} />
		</SkeletonTheme>
	);
};

export const ProductSearchLoader = props => {
	return (
		<SkeletonTheme baseColor="#e3e6e8" highlightColor="#c5c8c9">
			<div>
				<Skeleton
					count={3}
					height={80}
					style={{
						display: 'block',
						lineHeight: 2
					}}
				/>
			</div>
		</SkeletonTheme>
	);
};

export const CategorySearchLoader = props => {
	return (
		<SkeletonTheme baseColor="#e3e6e8" highlightColor="#c5c8c9">
			<Skeleton
				count={4}
				style={{
					display: 'block',
					lineHeight: 2
				}}
			/>
		</SkeletonTheme>
	);
};

export const CouponsLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<Skeleton style={{ lineHeight: 6.5 }} count={5} height={40} />
			</SkeletonTheme>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#444">
				<Skeleton style={{ marginTop: 30 }} height={40} width="50%" />
			</SkeletonTheme>
		</>
	);
};

export const CheckoutStep3Loader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<Skeleton style={{ marginTop: 20 }} count={4} height={30} />
			</SkeletonTheme>
		</>
	);
};

export const GiftcardOptionsLoader = props => {
	return (
		<div className="giftcard-loader">
			<SkeletonTheme baseColor="#eee" highlightColor="#ccc" inline={true}>
				<div className="title-option">
					<Skeleton style={{ marginTop: 20 }} height={30} count={1} />
				</div>
				<div className="options">
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
				</div>
				<div className="title-quantity">
					<Skeleton style={{ marginTop: 60 }} height={30} count={1} />
				</div>
				<div className="quantity">
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
				</div>
			</SkeletonTheme>
		</div>
	);
};
