import { api_addToWishlist, api_getProductsByIds, api_getWishlists, api_removeFromWishlist } from 'backend/api_calls';
import { notifyError, notifyInfo } from 'components/common/ToastMessages';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeAddToWishlistAction } from 'store/layout/actions';
import AddWishlist from './AddWishlist';

import { addToWishlistGTAG4Event } from 'common/gtag4';
import wishlist_icon from 'images/wishlist-icon.svg';
import addSVG from '../../images/add.svg';
import closeFilters from '../../images/close-filters.svg';
import removeSVG from '../../images/remove.svg';

const AddToWishlist = ({ productId }) => {
	const dispatch = useDispatch();
	const [wishlists, setWishlists] = useState([]);
	const { t } = useTranslation();
	const [addedToWishlist, setAddedToWishlist] = useState(false);
	const [showAddWishlist, setShowAddWishlist] = useState(false);
	const [product, setProduct] = useState({});

	const openAddWishlist = () => {
		setShowAddWishlist(true);
	};

	useEffect(() => {
		if (!productId) return;

		api_getProductsByIds(productId)
			.then(res => {
				const item = res.items.at(0);
				setProduct(item ?? {});
			})
			.catch(err => {
				console.error(err);
			});
	}, [productId]);

	useEffect(() => {
		if (showAddWishlist) return;

		api_getWishlists()
			.then(json => {
				setWishlists(json);
			})
			.catch(error => {
				notifyError(error);
				console.error(error);
			});
	}, [showAddWishlist]);

	const handleAddToWishlist = (e, item) => {
		e.preventDefault();

		let wishlistItem = {
			pid: productId,
			wid: item.id,
			product: {
				tcid: 0,
				cd: '0',
				nm: '0'
			}
		};

		api_addToWishlist(wishlistItem)
			.then(json => {
				notifyInfo(t('product_was_added_to_wishlist') + item.nm);
				dispatch(closeAddToWishlistAction());
				addToWishlistGTAG4Event(product);
			})
			.catch(error => {
				notifyError(error);
				console.error(error);
			});
	};

	useEffect(() => {
		if (addedToWishlist) {
			window.location.reload();
		}
	}, [addedToWishlist]);

	const handleRemoveFromWishlist = (e, item) => {
		// console.log(productId,".....")
		e.preventDefault();
		let wishlistItem = {
			pid: productId,
			wid: item.id,
			product: {
				tcid: 0,
				cd: '0',
				nm: '0'
			}
		};

		api_removeFromWishlist(wishlistItem)
			.then(json => {
				notifyInfo(t('product_removed_from_wishlist') + item.nm);
				// notifyInfo(t('product_removed_from_wishlist') + item.nm, () => {
				// 	setAddedToWishlist(true);
				// 	window.location.reload(); // This reloads the current page
				// });
				setAddedToWishlist(true);
				dispatch(closeAddToWishlistAction());
			})
			.catch(error => {
				notifyError(error);
				console.error(error);
			});
	};

	const handleCloseButton = () => {
		document.body.classList.remove('overflow');
		dispatch(closeAddToWishlistAction());
	};

	/*if (wishlists.length > 0) {
        console.log(wishlists, wishlists[0].wishlistItems.map(wishlistItem => wishlistItem.pid), productId);
    }*/
	if (!productId) return null;
	return (
		<>
			<div className="modal add-to-wish-pop">
				<div className="modal-content">
					<button className="exit" type="button">
						<img src={closeFilters} alt="exit button" onClick={() => handleCloseButton()} />
					</button>
					<h2 className="flex-col">
						<img className="desktop641" src={wishlist_icon} alt="wishlist icon" />
						{t('select_wishlist_title')}
					</h2>
					<div className="new-wishlist-addition wish-info">
						<p className="mobile641">{t('select_wishlist_description')}</p>
						<div className="wishlists-list grid-col">
							{wishlists.map(item => (
								<div className="mini-wishlist grid-col" key={item.id}>
									<div className="picture">
										{item.wishlistItems.length > 0 ? (
											<img src={item.wishlistItems[0].product.img1} height="78" width="78" />
										) : (
											<div className="no-img"></div>
										)}
									</div>
									<div className="info-wishlist">
										<div className="name">{item.nm}</div>
										<div className="quantity">
											{item.wishlistItems.length}
											<span>{t('products')}</span>
										</div>
									</div>
									{item.wishlistItems.map(wishlistItem => wishlistItem.pid).includes(productId) ? (
										<button type="button" onClick={e => handleRemoveFromWishlist(e, item)}>
											<img src={removeSVG} />
										</button>
									) : (
										<button type="button" onClick={e => handleAddToWishlist(e, item)}>
											<img src={addSVG} />
										</button>
									)}
								</div>
							))}
						</div>
					</div>
					<div className="add-address">
						<button className="flex-col" onClick={() => openAddWishlist()}>
							{t('add_to_wishlist')}
							<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
								<g id="plus" transform="translate(1 1)">
									<g id="plus-2" data-name="plus" transform="translate(8.5) rotate(45)">
										<line
											id="Line_488"
											data-name="Line 488"
											x2="12.021"
											y2="12.021"
											transform="translate(0 0)"
											fill="none"
											stroke="#000"
											strokeWidth="2"
										/>
										<line
											id="Line_489"
											data-name="Line 489"
											x1="12.021"
											y2="12.021"
											transform="translate(0 0)"
											fill="none"
											stroke="#000"
											strokeWidth="2"
										/>
									</g>
								</g>
							</svg>
						</button>
					</div>
				</div>
			</div>
			{showAddWishlist === true ? <AddWishlist setShowAddWishlist={setShowAddWishlist} /> : null}
		</>
	);
};

AddToWishlist.propTypes = {
	productId: PropTypes.number
};

export default AddToWishlist;
