// PersonalisedProducts object defined as a closure wrapper
var PersonalisedProducts = (function () {
	var isEmpty = function () {
		var items = getItems();
		return items.length == 0;
	};

	var getItems = function () {
		try {
			var items = JSON.parse(localStorage.getItem('personalised_products'));

			if (items == null) {
				items = [];
			}

			return items;
		} catch (e) {
			//console.log(e);
			return [];
		}
	};

	var clear = function () {
		try {
			localStorage.removeItem('personalised_products');
		} catch (e) {
			//console.log(e);
		}
	};

	var find = function (id) {
		var items = getItems();

		for (let item of items) {
			if (item.id == id) {
				return item;
			}
		}

		return null;
	};

	var add = function (products) {
		try {
			var items = getItems();
			var newItems = products
				.slice(0, 6)
				.map(product => product.id)
				.filter(val => !items.includes(val));

			if (newItems.length == 0) return;

			if (items.length == 0) {
				localStorage.setItem('personalised_products', JSON.stringify(newItems));
			} else if (items.length <= 3) {
				newItems = newItems.splice(0, 6 - items.length);
				items = newItems.concat(items);
				localStorage.setItem('personalised_products', JSON.stringify(items));
			} else {
				newItems = newItems.splice(0, 3);
				items = newItems.concat(items).splice(0, 6);
				localStorage.setItem('personalised_products', JSON.stringify(items));
			}

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var remove = function (token) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.id !== token.id || item.dimension !== token.dimension);

			if (filtered.length != items.length) {
				localStorage.setItem('personalised_products', JSON.stringify(filtered));
				return JSON.parse(localStorage.getItem('personalised_products'));
			}
		} catch (e) {
			//console.log(e);
		}

		return false;
	};

	return {
		isEmpty,
		getItems,
		clear,
		find,
		add,
		remove
	};
})();

export default PersonalisedProducts;
