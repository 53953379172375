import { all, fork } from 'redux-saga/effects';

// Add more saga functions here

// Auth
import authSaga from 'store/auth/saga';

// Checkout
import cartSaga from 'store/cart/saga';
import checkoutSaga from 'store/checkout/saga';

// Register
import registerSaga from 'store/register/saga';

export default function* rootSaga() {
	yield all([fork(authSaga), fork(cartSaga), fork(checkoutSaga), fork(registerSaga)]);
}
