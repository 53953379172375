import UserState from 'backend/user_session';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageHeaderMeta from '../../components/common/PageHeaderMeta';

import LoginSection from 'components/login/LoginSection';
import RegisterSection from 'components/login/RegisterSection';
import Register from 'pages/Customer/Register.js';

import LoadingSpinner from 'components/common/LoadingSpinner';
import { notifyError } from 'components/common/ToastMessages';

import ShopCart from 'backend/shop_cart';

import { api_getProfile } from 'backend/api_calls';
import { userInfoGTAG4Event } from 'common/gtag4';
import { clearAuthMessagesAction } from '../../store/actions.js';
{
	/*  import { sendCheckoutEvent } from "../common/gtm.js" */
}

const CheckoutStep1 = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const loading = useSelector(state => state.authReducer.loading);
	const successMessage = useSelector(state => state.authReducer.message);
	const authError = useSelector(state => state.authReducer.error);
	const dispatch = useDispatch();
	const [showTab1, setShowTab1] = useState(true);
	const [showTab2, setShowTab2] = useState(false);

	const [isShownRegister, setIsShownRegister] = useState(false);

	useEffect(() => {
		if (authError.length > 0) {
			notifyError(authError);
		}
	});

	useEffect(() => {
		if (UserState.isAuthenticated()) {
			api_getProfile().then(json => {
				userInfoGTAG4Event(json);
				navigate('/checkout-step-2');
			});
		} else if (UserState.getEmail()) {
			userInfoGTAG4Event({ eml: UserState.getEmail() });
		}
		if (successMessage.length > 0) {
			//notifyInfo(successMessage, () => {
			dispatch(clearAuthMessagesAction());
			navigate('/checkout-step-2');
			//})
		}
	}, [dispatch, navigate, successMessage]);
	useEffect(() => {
		// used to handle browser back/forward button
		if (ShopCart.getItems().length == 0) {
			navigate('/');
			return;
		}

		const checkoutStep = 1;
		{
			/* sendCheckoutEvent(checkoutStep, product); */
		}
	}, []);

	const toggleClass = e => {
		let classes = 'tab-log';
		let els = document.getElementsByClassName('tab-log active-log-tab');
		if (els) {
			while (els[0]) {
				els[0].classList.remove('active-log-tab');
			}
		}
		e.target.className = classes.replace('tab-log', 'tab-log active-log-tab');
		handleClick();
	};

	const handleClick = event => {
		if (document.getElementById('login').classList.contains('active-log-tab')) {
			setShowTab1(true);
			setShowTab2(false);
			setIsShownRegister(false);
		} else {
			setShowTab1(false);
			setShowTab2(true);
		}
	};

	let currentTab = '';
	if (!isShownRegister && showTab2) {
		currentTab = 'guest-register-content';
	} else if (showTab1) {
		currentTab = 'login-content';
	} else if (isShownRegister) {
		currentTab = 'register-content';
	}

	return (
		<>
			<PageHeaderMeta page_title={'Checkout Step 1'} />

			<div className={'login-modal checkout ' + currentTab}>
				<main>
					<ul className="flex-col">
						<li>
							<button id="register" className="tab-log" type="button" onClick={toggleClass}>
								{t('register')}
							</button>
						</li>
						<li>
							<button id="login" className="tab-log active-log-tab" type="button" onClick={toggleClass}>
								{t('sign_in_title')}
							</button>
						</li>
					</ul>

					{showTab1 && <LoginSection redirectOnLogin="checkout-step-2" />}
					{!isShownRegister && showTab2 && <RegisterSection setIsShownRegister={setIsShownRegister} />}
					{isShownRegister && <Register />}
				</main>
			</div>

			<LoadingSpinner isLoading={loading} />
		</>
	);
};

export default CheckoutStep1;
