export const addToWishlistAction = ({ productId }) => {
	return {
		type: 'add-to-wishlist',
		productId
	};
};

export const closeAddToWishlistAction = () => {
	return {
		type: 'close-add-to-wishlist'
	};
};

export const updateWishlistLastAction = handledProduct => {
	return {
		type: 'update-handled-product',
		handledProduct
	};
};
