import success_custom from 'images/success_custom.svg';
import logo from 'images/underground-logo.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CheckoutHeader = ({ step }) => {
	const { t } = useTranslation();

	const completedIcon = () => {
		return (
			<div className="completed">
				<img src={success_custom} />
			</div>
		);
	};

	return (
		<>
			<div className="shadow-header">
				<Link
					to="/"
					className="checkout-logo"
					onClick={() => {
						window.document.getElementsByTagName('header')[0].style.display = 'block';
					}}>
					<img src={logo} alt="Underground logo" />
				</Link>
				{step < 4 ? (
					<ol className="checkout-steps">
						<li className="checkout-step checkout-step-cart">
							<div className={`step-numb ${step === 1 ? 'active' : 'completed'}`}>
								{step == 1 ? '1' : completedIcon()}
								<span className={`step-line step-line-1 ${step == 1 ? '' : 'completed'} `}></span>
							</div>
							<div className="step-description">{t('cart')}</div>
						</li>
						<li className="checkout-step checkout-step-info">
							<div to={'/checkout-step-2'} className={`step-numb ${step === 2 ? 'active' : ''}`}>
								{step == 1 || step == 2 ? '2' : completedIcon()}
								<span className={`step-line step-line-2 ${step == 1 || step == 2 ? '' : 'completed'} `}></span>
							</div>
							<div className="step-description">{t('billing_delivery_info')}</div>
						</li>
						<li className="checkout-step checkout-step-payment">
							<div to={'/checkout-step-3'} className={`step-numb ${step === 3 ? 'active' : ''}`}>
								3
							</div>
							<div className="step-description">{t('checkoutheader_step3_title_mob')}</div>
						</li>
					</ol>
				) : (
					''
				)}
			</div>
		</>
	);
};

CheckoutHeader.propTypes = {
	step: PropTypes.number
};

export default CheckoutHeader;
