import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearAuthMessagesAction, loginAction } from 'store/auth/actions';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { api_getProfile } from 'backend/api_calls';
import { userInfoGTAG4Event } from 'common/gtag4';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { notifyError } from 'components/common/ToastMessages';
import useWindowSize from 'components/hooks/useWindowSize';
import eyeOffIcon from '../../images/eye-off.svg';
import eyeIcon from '../../images/eye.svg';
import AppleLoginButton from './AppleLoginButton';
import GoogleLoginButton from './GoogleLoginButton';

const LoginSection = ({ redirectOnLogin }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [isHuman, setHuman] = useState(true);
	const [visiblePassword, setVisiblePassword] = useState(false);
	const { width } = useWindowSize();

	const loading = useSelector(state => state.authReducer.loading);
	const successMessage = useSelector(state => state.authReducer.message);
	const authError = useSelector(state => state.authReducer.error);
	const dispatch = useDispatch();
	const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	useEffect(() => {
		if (authError.length > 0) {
			notifyError(authError, () => {
				dispatch(clearAuthMessagesAction());
			});
		}
	});

	useEffect(() => {
		if (successMessage.length > 0) {
			dispatch(clearAuthMessagesAction());

			if (redirectOnLogin) {
				navigate(redirectOnLogin);
				return;
			}

			if (width <= 1150) {
				navigate('/navigation');
				return;
			}

			//google tag events
			api_getProfile().then(res => {
				userInfoGTAG4Event(res);
			});

			navigate('/profile');
		}
	}, [successMessage]);

	const onCaptchaChange = value => {
		setHuman(true);
		//console.log("Captcha value:", value);
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (email == null || email.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (pass == null || pass.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (!isHuman) {
			notifyError('Please complete the CAPTCHA to proceed');
			return;
		}

		let args = {
			usr: email,
			psw: pass
		};

		dispatch(loginAction(args));
	};

	return (
		<>
			<div id="fb-root"></div>
			<div id="login-wrapper" className="narrow-wrapper">
				<form id="login-form" onSubmit={handleSubmit} method="POST">
					<div className="form-input text-label ">
						<input
							type="email"
							name="your-email"
							required
							value={email}
							onChange={e => setEmail(e.target.value)}
							placeholder="email"
						/>
						<label htmlFor="your-email">Email</label>
						<span className="red">*</span>
					</div>
					<div className="form-input text-label ">
						<input
							type={visiblePassword ? 'text' : 'password'}
							name="your-password"
							required
							value={pass}
							onChange={e => setPass(e.target.value)}
							placeholder="password"
						/>
						<label htmlFor="your-password">Password</label>
						<span className="red">*</span>
						<span
							id="togglePassVisibillity"
							className="pass-visibillity-icon"
							onClick={() => setVisiblePassword(!visiblePassword)}>
							<img src={visiblePassword ? eyeOffIcon : eyeIcon} alt="eye-show-pass" />
						</span>
					</div>

					<div className="forgot-password-link simple-link">
						<a href="/resetpasswordrequest" target="_blank" rel="noreferrer">
							{t('forgot_pass')}
						</a>
					</div>

					<div className="captcha-wrapper">
						{/*email.length > 0 && pass.length > 0 ? <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                            onChange={onCaptchaChange}
                        /> : null*/}
					</div>

					<button type="submit" className="submit arrow-button black-arrow-button" disabled={loading}>
						{t('sign_in')}
					</button>
				</form>
			</div>
			<div className="login-alter">
				<span>{t('sign_in_alternative')}</span>
				<div className="flex-col">
					{/* <FacebookLoginButton /> */}
					<GoogleOAuthProvider clientId={googleClientId}>
						<GoogleLoginButton />
					</GoogleOAuthProvider>
					<AppleLoginButton />
				</div>
			</div>

			<LoadingSpinner isLoading={loading} />
		</>
	);
};

LoginSection.propTypes = {
	redirectOnLogin: PropTypes.string
};

export default LoginSection;
