import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import UserSession from 'backend/user_session';
import { addToWishlistAction } from 'store/layout/actions';
import GuestWishlist from '../../backend/guest_wishlist';

const AddToWishlistButton = ({ product, updateWishList = () => {} }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const userAuthenticated = UserSession.isAuthenticated();
	const [wishlistActive, setWishlistActive] = useState(false);

	// Authed user Wishlist needs work.
	// Only Guest wishlist handling works.
	useEffect(() => {
		// Check if we need to set wish list via product property and then check guest wish list below...
		setWishlistActive(product.inwishlist);

		if (!UserSession.isAuthenticated()) {
			setWishlistActive(GuestWishlist.find(product.id));
		}
	}, [product.id]);

	const handleWishlistTrigger = () => {
		if (userAuthenticated) {
			dispatch(
				addToWishlistAction({
					productId: product.id
				})
			);

			updateWishList();
		} else {
			let status = GuestWishlist.addOrRemove(product);
			let message = '';

			if (status === 'added') {
				message = t('product_added_to_wishlist_guest');
				setWishlistActive(true);
			} else {
				message = t('product_removed_from_wishlist_guest');
				setWishlistActive(false);
			}
			// notifyInfo(message);

			updateWishList();
		}
	};

	return (
		<>
			<button
				type="button"
				className={`add-wishlist ${wishlistActive ? 'active' : ''}`}
				onClick={() => handleWishlistTrigger()}>
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 14.646 12.902">
					<path
						d="M14.917,5.549a3.59,3.59,0,0,0-5.079,0l-.692.692-.692-.692a3.591,3.591,0,1,0-5.079,5.079l.692.692L9.146,16.4l5.079-5.079.692-.692a3.59,3.59,0,0,0,0-5.079Z"
						transform="translate(-1.823 -3.997)"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
				</svg>
			</button>
		</>
	);
};

AddToWishlistButton.propTypes = {
	product: PropTypes.object
};

export default AddToWishlistButton;
