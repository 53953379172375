import ShopCart from 'backend/shop_cart';
import UserState from 'backend/user_session';
import { formatAmountValue } from 'common/helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PersonalisedProducts from '../../backend/personalised_products';
import CartItem from '../cart/CartItem.js';
import ProductBox from '../common/ProductBox';

import { api_composeCartTotals, api_getProductsByIds, api_updateCustomerDBCart } from 'backend/api_calls';
import UserSession from 'backend/user_session';
import shippingCustomIcon from 'images/shipping-custom-icon.svg';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import closeWidget from '../../images/close-filters.svg';
import { headerCartQuantityAction, showCheckoutModal } from '../../store/actions.js';
import { openCartWidgetAction } from '../../store/cart/actions.js';
import { notifyError } from './ToastMessages.js';

const CartWidget = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [cartItems, setCartItems] = useState(ShopCart.getItems());
	const [orderTotals, setOrderTotals] = useState({});
	const firstUpdate = useRef(true);
	const lastItem = JSON.parse(localStorage.getItem('shopcart_last_item'));
	const [products, setProducts] = useState([]);
	let productsIds = PersonalisedProducts.getItems();

	const limit = process.env.REACT_APP_FREE_DELIVERY_AMOUNT;

	const closeCart = () => {
		document.body.classList.remove('cart-open');
		dispatch(openCartWidgetAction(false));
	};

	const goCheckout = e => {
		const cartItemErrors = document.getElementsByClassName('cart-item-error');

		if (cartItemErrors.length > 0) {
			notifyError(t('cart_fix_errors_prompt'));
		} else if (ShopCart.isEmpty() || !UserState.hasValidOrderState()) {
			document.body.classList.add('overflow');
			dispatch(showCheckoutModal(true));
			closeCart();
		} else {
			navigate('/checkout-step-2');
			closeCart();
		}
	};

	const deliveryFee = totalAmount => {
		if (totalAmount === undefined || totalAmount >= limit) {
			return null;
		} else {
			let dif = limit - totalAmount;
			let text = t('delivery_fee').replace('{price}', formatAmountValue(dif));
			return (
				<>
					<li className="flex-col">
						<img src={shippingCustomIcon} alt="shipping" />
						{text}
					</li>
				</>
			);
		}
	};

	useEffect(() => {
		if (cartItems.length > 0) {
			api_composeCartTotals(cartItems).then(json => {
				setOrderTotals(json);
			});
		}
		dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));

		if (!firstUpdate.current && UserSession.isAuthenticated()) {
			api_updateCustomerDBCart({ items: cartItems });
		}

		firstUpdate.current = false;
	}, [cartItems, dispatch]);

	useEffect(() => {
		const cancel = { value: false };
		api_getProductsByIds(productsIds.toString())
			.then(json => {
				if (!cancel.value) setProducts(json.items);
			})
			.catch(error => console.error(error));
		return () => (cancel.value = true);
	}, [productsIds]);

	if (cartItems.length === 0) {
		return (
			<div id="cart-widget">
				<div className="transparent-side" onClick={() => closeCart()}></div>
				<div className="cart-widget">
					<div className="empty-content">{t('no_items_in_cart')}</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div id="cart-widget" className="flex-col">
				<div className="cart-widget">
					<div className="close-widget">
						<img src={closeWidget} alt="close widget" onClick={() => closeCart()}></img>
					</div>
					<div className="product-added">
						<div className="head-account">
							<h2>{t('added_to_cart')}</h2>
						</div>
						<div className="product-item">
							<CartItem cartItem={lastItem} key={[1]} setCartItems={setCartItems} />
						</div>

						<div className="sticky-cart-total">
							{/* <div className="total flex-col">
                <div className="name">{t("order_total")}</div>
                <div className="amount">
                  {formatAmountValue(orderTotals.gamt)}
                </div>
              </div> */}

							<div className="buttons-cart flex-col">
								<button
									type="button"
									className="submit white-button"
									onClick={() => {
										closeCart();
									}}>
									{t('back_to_products')}
								</button>
								<button
									type="button"
									className="submit arrow-button black-arrow-button"
									onClick={() => {
										closeCart();
										navigate('/cart');
									}}>
									{t('continue_to_cart')}
								</button>
							</div>
							<ul className="cart-ship-info grid-col">{deliveryFee(orderTotals.gamt)}</ul>
						</div>
					</div>
					<div className="related-products">
						<h3 className="related-title">{t('related_products')}</h3>
						<div className="related-container">
							{products.slice(0, 4).map(item => (
								<ProductBox key={item.id} product={item} closeCart={closeCart} fromWidget={true} />
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CartWidget;
