import { formatAmount } from './helper';

const handleConstructProductObject = (product, listName = null, catAsListname = false) => {
	const productObject = {};

	// If product is cartItem get xml_id for selected dimension
	if (product.dimension) {
		const selectedDimension = product.dimensions.find(x => x.id === product.dimension && x.cval);
		productObject.item_id = selectedDimension
			? `${product.id}-${selectedDimension.cval}`
			: `${product.id}-${product.dimensions.find(x => x.cval)?.cval}`;
	} else {
		// otherwise just get the xml_id from the first dimension
		const dimension = product.dimensions.find(x => x.cval);
		productObject.item_id = dimension ? `${product.id}-${dimension.cval}` : product.id;
	}
	productObject.item_name = product.nm;
	productObject.discount = formatAmount(product.cdsc);

	if (product.index >= 0) {
		productObject.index = product.index;
	}

	productObject.item_brand = product.mfnm;

	product.breadcrumbs.map((item, index) => {
		let key = 'item_category';
		if (index === 0) {
			key = `item_category`;
		} else {
			key = `item_category${index + 1}`;
		}
		productObject[key] = item.nm;
	});

	productObject.price = product.cprc;

	if (listName) productObject.item_list_name = listName;

	if (catAsListname) {
		const categoryNames = product.cnm?.split(',') ?? [];
		const listName = categoryNames.at(0) ?? null;
		productObject.item_list_name = listName ? 'Category: ' + listName : null;
	}

	// For cart view
	if (product.quantity) productObject.quantity = product.quantity;

	return productObject;
};

// USER INFO - done (LoginSection (I have added / I have not checked it) && CheckoutStep1)
export const userInfoGTAG4Event = (profile = {}) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		user_data: {
			userId: profile.id,
			email: profile.eml,
			phone_number: profile?.mob ?? '',
			address: {
				first_name: profile?.fn ?? '',
				last_name: profile?.ln ?? '',
				street: profile.address?.adl ?? '',
				city: profile.address?.ct ?? '',
				region: '',
				postal_code: profile.address?.pc ?? ''
			}
		}
	});
};

// VIEW ITEM LIST - done (ProductsPersonalized && DiscountedList && RecentlyProducts (not checked) && CategoryList)
//not done RecommendedProducts (do not find) && Search (already exists with comments) && StatusList (do not find))
export const viewItemListGTAG4Event = (products, listName) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			items: products.map(product => handleConstructProductObject(product, listName))
		}
	});
};

// SELECT ITEM - FREEZED (inserted in Search.js && CategoryList.js but doesn't work)
// not inserted at StatusList.js (there is no such a file)
export const selectItemGTAG4Event = (product, listName) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'select_item',
		ecommerce: {
			item_list_name: listName,
			items: [handleConstructProductObject(product)]
		}
	});
};

// VIEW ITEM - done
export const viewItemGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'view_item',
		ecommerce: {
			currency: 'EUR',
			value: product.cprc,
			items: [handleConstructProductObject(product, undefined, true)]
		}
	});
};

// ADD TO WISHLIST - done
export const addToWishlistGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_to_wishlist',
		ecommerce: {
			currency: 'EUR',
			items: [handleConstructProductObject(product, undefined, true)]
		}
	});
};

// ADD TO CART - done
export const addToCartGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_to_cart',
		ecommerce: {
			currency: 'EUR',
			value: product.cprc,
			items: [handleConstructProductObject(product)]
		}
	});
};

// REMOVE FROM CART - done
export const removeFromCartGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'remove_from_cart',
		ecommerce: {
			currency: 'EUR',
			value: product.cprc,
			items: [handleConstructProductObject(product)]
		}
	});
};

// VIEW CART - done
export const viewCartGTAG4Event = (products, cartTotals) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'view_cart',
		ecommerce: {
			currency: 'EUR',
			value: cartTotals,
			items: products.map(product => handleConstructProductObject(product, undefined, true))
		}
	});
};

// BEGIN CHECKOUT - done
export const beginCheckoutGTAG4Event = (products, orderTotals) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'begin_checkout',
		ecommerce: {
			currency: 'EUR',
			value: orderTotals,
			items: products.map(product => handleConstructProductObject(product, undefined, true))
		}
	});
};

// ADD SHIPPING INFORMATION (added on ShippingOptionsContainer && ShippingOptionPickup(not checked))
export const addShippingInfoGTAG4Event = (products, orderTotals, courierLabel) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_shipping_info',
		ecommerce: {
			currency: 'EUR',
			value: orderTotals,
			shipping_tier: courierLabel,
			items: products.map(product => handleConstructProductObject(product, undefined, true))
		}
	});
};

// ADD PAYMENT INFORMATION (added on PaymentOption && PaymentOptionBankDeposit && PaymentOptionsContainer(not checked))
export const addPaymentInfoGTAG4Event = (products, orderTotals, paymentLabel) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_payment_info',
		ecommerce: {
			currency: 'EUR',
			value: orderTotals,
			payment_type: paymentLabel,
			items: products.map(product => handleConstructProductObject(product, undefined, true))
		}
	});
};

// PURCHASE - done
export const purchaseGTAG4Event = (products, orderUid, orderTotals, taxTotal, shippingTotals, coupons) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'purchase',
		ecommerce: {
			transaction_id: orderUid,
			value: orderTotals,
			tax: taxTotal,
			shipping: shippingTotals,
			currency: 'EUR',
			coupons: coupons,
			items: products.map(product => handleConstructProductObject(product, undefined, true))
		}
	});
};
