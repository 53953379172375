export const checkoutErrorAction = error => {
	return {
		type: 'checkout-error',
		error
	};
};

export const setCoupons = coupons => {
	return {
		type: 'set-coupons',
		coupons
	};
};

export const setGiftcards = giftcards => {
	return {
		type: 'set-giftcards',
		giftcards
	};
};

export const shipToBillingAddressChangeAction = shipToBillingAddress => {
	return {
		type: 'ship-to-billing-changed',
		shipToBillingAddress
	};
};

export const receiptTypeSelectAction = receiptType => {
	return {
		type: 'receipt-type-selected',
		receiptType
	};
};

export const setSelectedAddressIdAction = selectedAddressId => {
	return {
		type: 'set-selected-address-id',
		selectedAddressId
	};
};

export const selectedNewAddressAction = selectedNewAddress => {
	return {
		type: 'has-selected-new-address',
		selectedNewAddress
	};
};

export const setInputsErrorsAction = inputs => {
	return {
		type: 'set-inputs-errors',
		inputs
	};
};

export const setPickupFromStoreAction = pickup => {
	return {
		type: 'set-pickup-from-store',
		pickup
	};
};

export const setTakeAwayStoreId = storeId => {
	return {
		type: 'set-take-away-store-id',
		storeId
	};
};

export const shippingSelectAction = shipping => {
	return {
		type: 'shipping-selected',
		shipping
	};
};

export const paymentSelectAction = payment => {
	return {
		type: 'payment-selected',
		payment
	};
};

export const orderChangeAction = order => {
	return {
		type: 'order-changed',
		order
	};
};

export const setOrderTotals = totals => {
	return {
		type: 'set-order-totals',
		totals
	};
};

export const newsletterToggledAction = newsletterAccept => {
	return {
		type: 'newsletter-toggled',
		newsletterAccept
	};
};

export const privacyPolicyToggledAction = privacyPolicyAccept => {
	return {
		type: 'privacy-policy-toggled',
		privacyPolicyAccept
	};
};

export const loyaltyPointChange = selectedLoyaltyPoints => {
	return {
		type: 'change-loyalty-points',
		selectedLoyaltyPoints
	};
};

export const isGiftToggledAction = isGift => {
	return {
		type: 'gift-toggled',
		isGift
	};
};

export const orderCompleteAction = order => {
	return {
		type: 'order-completed',
		order
	};
};

export const orderCompleteSuccessAction = response => {
	return {
		type: 'order-complete-success',
		response
	};
};

export const checkoutClearMessages = () => {
	return {
		type: 'clear-messages'
	};
};

export const resetCheckoutStore = () => {
	return {
		type: 'reset-checkout-store'
	};
};
