const initialState = {
	url: new URLSearchParams(window.location.search).get('q'),
	sorting: new URLSearchParams(window.location.search).get('sort'),
	viewPerPage: parseInt(new URLSearchParams(window.location.search).get('psize')) || 24,
	page: parseInt(new URLSearchParams(window.location.search).get('page')) || 1,
	loading: false
};

const filtersReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'flt-apply':
			state = { ...state, url: action.url, loading: true };
			break;
		case 'flt-reset':
			state = { ...state, url: '', loading: false };
			break;
		case 'flt-sorting-apply':
			state = { ...state, sorting: action.option, loading: false };
			break;
		case 'flt-view-per-page-apply':
			state = { ...state, viewPerPage: action.option, loading: false };
			break;
		case 'page-change':
			state = { ...state, page: action.page, loading: true };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default filtersReducer;
