import PropTypes from 'prop-types';
import React from 'react';

const LoadingSpinner = props => {
	let loadingClass = 'loading-spinner';

	if (props.isLoading) {
		loadingClass += ' visible';
	}

	return (
		<div id="loadingSpinner" className={loadingClass}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

LoadingSpinner.propTypes = {
	isLoading: PropTypes.bool
};

export default LoadingSpinner;
