import UserSession from 'backend/user_session';
import InfoMessage from 'common/InfoMessage';
import CartWidget from 'components/common/CartWidget.js';
import cartIcon from 'images/cart-icon.svg';
import flagEn from 'images/lang-en.svg';
import flagGr from 'images/lang-gr.svg';
import searchIconWhite from 'images/search-icon-white.svg';
import searchIcon from 'images/search-icon.svg';
import logo from 'images/underground-logo.svg';
import userIcon from 'images/user-icon.svg';
import wishlistIcon from 'images/wishlist-icon.svg';
import CheckoutStep1 from 'pages/Checkout/CheckoutStep1.js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { showLoginModal } from 'store/actions.js';
import MenuSection from './MenuSection';
import Search from './Search';

const ClassicHeader = ({ searchTransition, setSearchTransition }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [isMenuActive, setMenuActive] = useState(false);
	const headerCartQuantity = useSelector(state => state.cartReducer.headerCartQuantity);
	const showCheckoutLoginModal = useSelector(state => state.authReducer.showCheckoutLoginModal);
	const openCartWidget = useSelector(state => state.cartReducer.showCartWidget);

	const toggleLogin = () => {
		document.body.classList.add('overflow');
		dispatch(showLoginModal(true));
	};

	const closeMobileAccount = () => {
		window.document.querySelector('.login-register-mobile').classList.remove('active');
	};

	//const changeLanguage = () => {
	//	const oldLang = localStorage.getItem('I18N_LANGUAGE');
	//	const lang = localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'en' : 'el';
	//	let { pathname } = location;

	//	i18n.changeLanguage(lang);
	//	localStorage.setItem('I18N_LANGUAGE', lang);

	//	// Update url
	//	var url = '';

	//	if (pathname == null || pathname == '') {
	//		pathname = '/';
	//	}
	//	if (pathname.length > 3 && !pathname.startsWith('/el/') && !pathname.startsWith('/en/')) {
	//		url = '/' + lang + pathname;
	//	} else {
	//		if (pathname.startsWith('/' + oldLang)) {
	//			url = pathname.replace('/' + oldLang, '/' + lang);
	//		} else {
	//			url = '/' + lang + pathname;
	//		}
	//	}

	//	window.location.href = url;
	//};

	const toggleMobileMenu = () => {
		setMenuActive(!isMenuActive);
	};

	return (
		<React.Fragment>
			{showCheckoutLoginModal && <CheckoutStep1 />}

			<header>
				<InfoMessage className="topbar-message" code="top-message" hasCloseButton={true} />
				<div className="top-header-row">
					<div className="content-wrapper">
						<div className="part left-part">
							<Link to="/contact">
								<svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
									<path
										id="message"
										d="M17,10.4a5.819,5.819,0,0,1-.625,2.639A5.9,5.9,0,0,1,11.1,16.306a5.819,5.819,0,0,1-2.639-.625L4.5,17l1.319-3.958A5.819,5.819,0,0,1,5.194,10.4,5.9,5.9,0,0,1,8.458,5.125,5.819,5.819,0,0,1,11.1,4.5h.347A5.889,5.889,0,0,1,17,10.056Z"
										transform="translate(-4 -4)"
										fill="none"
										stroke="#b6bac2"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1"
									/>
								</svg>
								{t('need_help')}
							</Link>
						</div>
						<div className="part right-part">
							<Link to="/giftcard">{t('gift_card')}</Link>
							<Link to="/storefinder">{t('stores-top')}</Link>
							{/*<button*/}
							{/*	type="button"*/}
							{/*	id="lang-name"*/}
							{/*	className={localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'lang-gr' : 'lang-en'}*/}
							{/*	onClick={() => changeLanguage()}>*/}
							{/*	{localStorage.getItem('I18N_LANGUAGE') == 'el' ? (*/}
							{/*		<>*/}
							{/*			{' '}*/}
							{/*			<img src={flagGr} /> <span>GR</span>{' '}*/}
							{/*		</>*/}
							{/*	) : (*/}
							{/*		<>*/}
							{/*			{' '}*/}
							{/*			<img src={flagEn} /> <span>EN</span>{' '}*/}
							{/*		</>*/}
							{/*	)}*/}
							{/*</button>*/}
						</div>
					</div>
				</div>
				<div className="content-wrapper">
					<button id="mobile-menu-button" onClick={toggleMobileMenu}>
						<svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16">
							<g id="burger_menu" data-name="burger menu" transform="translate(-22 -54.729)">
								<line
									id="Line_27"
									data-name="Line 27"
									x2="26"
									transform="translate(22 55.729)"
									fill="none"
									stroke="#fff"
									strokeWidth="2"
								/>
								<line
									id="Line_29"
									data-name="Line 29"
									x2="26"
									transform="translate(22 69.729)"
									fill="none"
									stroke="#fff"
									strokeWidth="2"
								/>
								<line
									id="Line_28"
									data-name="Line 28"
									x2="26"
									transform="translate(22 62.729)"
									fill="none"
									stroke="#fff"
									strokeWidth="2"
								/>
							</g>
						</svg>
					</button>

					<div id="logo" className={searchTransition ? 'search-opened' : ''}>
						<Link to="/">
							<img src={logo} alt="UNDERGROUND" />
						</Link>
					</div>

					{!searchTransition && (
						<div className={isMenuActive ? 'visible-menu active' : 'visible-menu'}>
							<MenuSection toggleMobileMenu={toggleMobileMenu} />
						</div>
					)}

					{searchTransition && (
						<CSSTransition in={searchTransition} timeout={300} classNames="search-transition" unmountOnExit>
							<Search setSearchTransition={setSearchTransition} />
						</CSSTransition>
					)}
					<div className="header-options">
						{!searchTransition && (
							<div id="view-search">
								<div
									// to="#_"
									className="search-input active"
									onClick={() => {
										setSearchTransition(setSearchTransition => !setSearchTransition);
									}}>
									<span>{t('search_products_brands')}</span>
									<img src={searchIcon} alt="SEARCH" />
								</div>
							</div>
						)}

						<div
							id="view-wishlist"
							onClick={() => {
								closeMobileAccount();
							}}>
							{UserSession.isAuthenticated() ? (
								<Link to="/wishlists" className="active">
									<img src={wishlistIcon} alt="WISHLIST" />
								</Link>
							) : (
								<Link to="/wishlist" className="active">
									<img src={wishlistIcon} alt="WISHLIST" />
								</Link>
							)}
						</div>

						<div
							id="user-login"
							onClick={() => {
								closeMobileAccount();
							}}>
							{UserSession.isAuthenticated() ? (
								<Link to="/profile" className="active">
									<img src={userIcon} alt="LOGIN" />
								</Link>
							) : (
								<Link type="button" className="active" to="/login">
									<img src={userIcon} alt="LOGIN" />
								</Link>
							)}
						</div>

						<div className="search-mobile">
							<Link
								to="#_"
								className="active"
								onClick={() => {
									setSearchTransition(setSearchTransition => !setSearchTransition);
								}}>
								<img src={searchIconWhite} alt="SEARCH" />
							</Link>
						</div>

						<div id="view-cart">
							<Link
								to={'/cart'}
								className="active"
								onClick={() => {
									closeMobileAccount();
								}}>
								<img src={cartIcon} alt="CART" />
								<span>{headerCartQuantity}</span>
							</Link>
						</div>
					</div>
				</div>
			</header>

			{openCartWidget && <CartWidget />}

			{/*Login - Register Section for mobile*/}
			<div className="login-register-mobile">
				{UserSession.isAuthenticated() ? (
					<React.Fragment>
						<button className="login-button" onClick={closeMobileAccount}>
							<Link to="/profile">{t('my_account')}</Link>
						</button>
						<button className="register-button" onClick={closeMobileAccount}>
							<Link to="/profile">{t('my_orders')}</Link>
						</button>
					</React.Fragment>
				) : (
					<React.Fragment>
						<button
							className="login-button"
							onClick={() => {
								toggleLogin();
								closeMobileAccount();
							}}>
							{t('sign_in')}
						</button>
						<button
							className="register-button"
							onClick={() => {
								toggleLogin();
								closeMobileAccount();
							}}>
							{t('register')}
						</button>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

ClassicHeader.propTypes = {
	searchTransition: PropTypes.bool,
	setSearchTransition: PropTypes.func
};

export default ClassicHeader;
